import React, { Fragment, useEffect } from "react"

import SplitBackground from "../components/SplitBackground"
import HorizontalScrollIndicator from "../components/HorizontalScrollIndicator"

import Helmet from "../components/Helmet"
import Container from "../components/Container"
import Home from "../components/Home"
import ServicesSection from "../components/ServicesSection"
import ProjectsSection from "../components/ProjectsSection"
import About from "../components/About"
import Contact from "../components/Contact"

const App = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      observables => {
        observables.forEach(observable => {
          if (observable.intersectionRatio > 0.5) {
            observable.target.classList.remove("not-visible")
            observer.unobserve(observable.target)
          }
        })
      },
      {
        threshold: [0.5],
      }
    )
    const elements = document.getElementsByClassName("intersection-observed")
    const { length } = elements
    for (let i = 0; i < length; i += 1) {
      elements[i].classList.add("not-visible")
      observer.observe(elements[i])
    }
  }, []) // eslint-disable-line
  return (
    <Fragment>
      <SplitBackground />
      <HorizontalScrollIndicator />
      <Helmet />
      <Container>
        <Home />
        <ServicesSection />
        <ProjectsSection />
        <About />
        <Contact />
      </Container>
    </Fragment>
  )
}

export default App
