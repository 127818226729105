import React from "react"
import PropTypes from "prop-types"

import "./style.less"

const Project = ({ project, onClick }) => (
  <div
    title={project.name}
    className="project-container intersection-observed"
    onClick={onClick}
  >
    <div className="project-image">
      <img alt={project.name} src={project.image} />
    </div>
  </div>
)

Project.propTypes = {
  project: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
}

Project.defaultProps = {
  onClick: () => {},
}

export default Project
