const INFORMATION_MESSAGE = {
  INVALID_MAIL: "Email invalide.",
  INVALID_OBJECT: "L'objet est vide.",
  INVALID_MESSAGE: "Le message est vide.",
  SEND: "Le message a étais envoyé",
  NOT_SEND: "Le message n'a pas étais envoyé !",
}

const OTHER = {}

export { INFORMATION_MESSAGE, OTHER }
