import React from "react"
import PropTypes from "prop-types"
import Icon from "../../Icon"

import "./style.less"

const Service = ({ service }) => (
  <div
    title={service.title}
    className="service-container intersection-observed"
  >
    <h3 className="service-name">{service.name}</h3>
    <Icon className="service-icon" classIcon={service.logo} />
    <p className="service-description">{service.description}</p>
  </div>
)

Service.propTypes = {
  service: PropTypes.shape().isRequired,
}

export default Service
