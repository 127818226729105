import './style.less';

import React, { useState } from 'react';

import Modal from '../Modal';
import PROJECTS from '../../config/projects';
import Project from './Project';

const ProjectsSection = () => {
  const [modal, setModal] = useState({
    show: false,
    title: '',
    body: '',
  });

  function closeProjectInfoModal() {
    setModal({
      show: false,
      title: '',
      body: '',
    });
  }

  function openProjectInfoModal(project) {
    setModal({
      show: true,
      title: project.name,
      body: (
        <div className="project-content">
          <div className="project-image">
            <img alt={project.name} src={project.image} />
          </div>
          <table className="project-info">
            <tbody>
              {project.link && (
                <tr>
                  <td>Lien: </td>
                  <td>
                    <a
                      href={`https://${project.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {project.link}
                    </a>
                  </td>
                </tr>
              )}
              <tr>
                <td>Catégorie: </td>
                <td>{project.category}</td>
              </tr>
              <tr>
                <td>Description: </td>
                <td>{project.description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    });
  }

  return (
    <div className="content projects-section">
      <h2 className="intersection-observed">Quelques Projets</h2>
      {PROJECTS.map(project => (
        <Project
          key={project.name}
          project={project}
          onClick={() => openProjectInfoModal(project)}
        />
      ))}
      <Modal
        className="modal-project"
        show={modal.show}
        title={modal.title}
        body={modal.body}
        onClose={() => closeProjectInfoModal()}
      />
    </div>
  );
};

export default ProjectsSection;
