import React from "react"

import Service from "./Service"
import SERVICES from "../../config/services"

import "./style.less"

const ServicesSection = () => (
  <div className="content services-section">
    <h2 className="intersection-observed">Services</h2>
    {SERVICES.map(service => (
      <Service key={service.name} service={service} />
    ))}
  </div>
)

export default ServicesSection
