import { INFORMATION_MESSAGE } from './constants';
import InformationMessage from '../InformationMessage';
import { Loader } from '../Icon';
import React from 'react';
import { isEmpty } from '../../utils/checker';
import { isValidMail } from '../../utils/validators';
import lessStyle from './style.module.less';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

/**
 * Contact Component
 */
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        sendMail: {
          loading: false,
          finish: false,
          success: false,
        },
      },
      formInputs: {
        email: {
          value: '',
          error: false,
          informationMessage: {
            display: false,
            value: INFORMATION_MESSAGE.INVALID_MAIL,
          },
        },
        object: {
          value: '',
          error: false,
          informationMessage: {
            display: false,
            value: INFORMATION_MESSAGE.INVALID_OBJECT,
          },
        },
        message: {
          value: '',
          error: false,
          informationMessage: {
            display: false,
            value: INFORMATION_MESSAGE.INVALID_MESSAGE,
          },
        },
        button: {
          informationMessage: {
            display: false,
            value: INFORMATION_MESSAGE.NOT_SEND,
            success: false,
          },
        },
      },
    };

    this.handleChangeEmailValue = this.handleChangeEmailValue.bind(this);
    this.handleChangeObjectValue = this.handleChangeObjectValue.bind(this);
    this.handleChangeMessageValue = this.handleChangeMessageValue.bind(this);
    this.handleSendMail = this.handleSendMail.bind(this);
  }

  /**
   * Handle Change Value For Email Input
   */
  handleChangeEmailValue(e) {
    const { formInputs } = this.state;
    const { value } = e.target;
    this.setState({
      formInputs: {
        ...formInputs,
        email: {
          ...formInputs.email,
          informationMessage: {
            ...formInputs.email.informationMessage,
            display: false,
          },
          value,
          error: !isValidMail(value),
        },
        button: {
          ...formInputs.button,
          informationMessage: {
            ...formInputs.message.informationMessage,
            display: false,
          },
        },
      },
    });
  }

  /**
   * Handle Change Value For Object Input
   */
  handleChangeObjectValue(e) {
    const { formInputs } = this.state;
    const { value } = e.target;
    this.setState({
      formInputs: {
        ...formInputs,
        object: {
          ...formInputs.object,
          informationMessage: {
            ...formInputs.object.informationMessage,
            display: false,
          },
          value,
          error: isEmpty(value),
        },
        button: {
          ...formInputs.button,
          informationMessage: {
            ...formInputs.message.informationMessage,
            display: false,
          },
        },
      },
    });
  }

  /**
   * Handle Change Value For Message Input
   */
  handleChangeMessageValue(e) {
    const { formInputs } = this.state;
    const { value } = e.target;
    this.setState({
      formInputs: {
        ...formInputs,
        message: {
          ...formInputs.message,
          informationMessage: {
            ...formInputs.message.informationMessage,
            display: false,
          },
          value,
          error: isEmpty(value),
        },
        button: {
          ...formInputs.button,
          informationMessage: {
            ...formInputs.message.informationMessage,
            display: false,
          },
        },
      },
    });
  }

  /**
   * Check Form data for mail.
   */
  checkFormData() {
    const { formInputs } = this.state;
    return (
      isValidMail(formInputs.email.value)
      && !isEmpty(formInputs.object.value)
      && !isEmpty(formInputs.message.value)
    );
  }

  /**
   * Send Mail
   */
  handleSendMail() {
    const { status, formInputs } = this.state;
    if (!status.sendMail.loading) {
      if (this.checkFormData()) {
        this.setState({
          status: {
            ...status,
            sendMail: {
              loading: true,
              success: false,
              finish: false,
            },
          },
          formInputs: {
            ...formInputs,
            button: {
              ...formInputs.button,
              formInputs: {
                ...formInputs.button.formInputs,
                display: false,
              },
            },
          },
        });
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            email: formInputs.email.value,
            object: formInputs.object.value,
            message: formInputs.message.value,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Not 2xx response');
            } else {
              this.handleSendMailSuccess();
            }
          })
          .catch(() => this.handleSendMailFailure());
      } else {
        this.setState({
          formInputs: {
            ...formInputs,
            email: {
              ...formInputs.email,
              error: !isValidMail(formInputs.email.value),
              informationMessage: {
                ...formInputs.email.informationMessage,
                display: !isValidMail(formInputs.email.value),
              },
            },
            object: {
              ...formInputs.object,
              error: isEmpty(formInputs.object.value),
              informationMessage: {
                ...formInputs.object.informationMessage,
                display: isEmpty(formInputs.object.value),
              },
            },
            message: {
              ...formInputs.message,
              error: isEmpty(formInputs.message.value),
              informationMessage: {
                ...formInputs.message.informationMessage,
                display: isEmpty(formInputs.message.value),
              },
            },
          },
        });
      }
    }
  }

  /**
   * Send Mail Success
   */
  handleSendMailSuccess() {
    const { status, formInputs } = this.state;
    this.setState({
      status: {
        ...status,
        sendMail: {
          loading: false,
          success: true,
          finish: true,
        },
      },
      formInputs: {
        ...formInputs,
        email: {
          ...formInputs.email,
          value: '',
          error: false,
        },
        object: {
          ...formInputs.object,
          value: '',
          error: false,
        },
        message: {
          ...formInputs.message,
          value: '',
          error: false,
        },
        button: {
          ...formInputs.button,
          informationMessage: {
            success: true,
            value: INFORMATION_MESSAGE.SEND,
            display: true,
          },
        },
      },
    });
  }

  /**
   * Send Mail Failure
   */
  handleSendMailFailure() {
    const { status, formInputs } = this.state;
    this.setState({
      status: {
        ...status,
        sendMail: {
          loading: false,
          success: false,
          finish: true,
        },
      },
      formInputs: {
        ...formInputs,
        button: {
          ...formInputs.button,
          informationMessage: {
            success: false,
            value: INFORMATION_MESSAGE.NOT_SEND,
            display: true,
          },
        },
      },
    });
  }

  /**
   * React Rendre method
   */
  render() {
    const { status, formInputs } = this.state;
    return (
      <div id="anchor-contact" className={`${lessStyle.contact}`}>
        <h2 className="intersection-observed">Contact</h2>
        <form
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSendMail();
          }}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label htmlFor="bot-field">
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <div className={lessStyle.inputContainer}>
            <input
              className={`intersection-observed input input-email ${
                formInputs.email.error ? 'error' : ''
              }`}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={formInputs.email.value}
              onChange={this.handleChangeEmailValue}
              required
            />
            {formInputs.email.informationMessage.display && (
              <InformationMessage
                type="danger"
                message={formInputs.email.informationMessage.value}
                handleOnClose={() => this.setState({
                  formInputs: {
                    ...formInputs,
                    email: {
                      ...formInputs.email,
                      informationMessage: {
                        ...formInputs.email.informationMessage,
                        display: false,
                      },
                    },
                  },
                })
                }
              />
            )}
          </div>
          <div className={lessStyle.inputContainer}>
            <input
              className={`intersection-observed input input-object ${
                formInputs.object.error ? 'error' : ''
              }`}
              name="object"
              placeholder="Objet"
              value={formInputs.object.value}
              onChange={this.handleChangeObjectValue}
            />
            {formInputs.object.informationMessage.display
              && !formInputs.email.informationMessage.display && (
                <InformationMessage
                  type="danger"
                  message={formInputs.object.informationMessage.value}
                  handleOnClose={() => this.setState({
                    formInputs: {
                      ...formInputs,
                      object: {
                        ...formInputs.object,
                        informationMessage: {
                          ...formInputs.object.informationMessage,
                          display: false,
                        },
                      },
                    },
                  })
                  }
                />
            )}
          </div>

          <textarea
            className={`input intersection-observed ${lessStyle.inputMessage} ${
              formInputs.message.error ? 'error' : ''
            }`}
            name="message"
            placeholder="Votre message"
            value={formInputs.message.value}
            onChange={this.handleChangeMessageValue}
          >
          </textarea>
          {formInputs.message.informationMessage.display
            && !formInputs.email.informationMessage.display
            && !formInputs.object.informationMessage.display && (
              <InformationMessage
                type="danger"
                message={formInputs.message.informationMessage.value}
                handleOnClose={() => this.setState({
                  formInputs: {
                    ...formInputs,
                    message: {
                      ...formInputs.message,
                      informationMessage: {
                        ...formInputs.message.informationMessage,
                        display: false,
                      },
                    },
                  },
                })
                }
              />
          )}
          <div className={lessStyle.buttonContainer}>
            <button
              className="button intersection-observed"
              type="submit"
              disabled={status.sendMail.loading}
            >
              Envoyer
              {status.sendMail.loading && <Loader />}
            </button>
            {formInputs.button.informationMessage.display && (
              <InformationMessage
                type={
                  formInputs.button.informationMessage.success
                    ? 'success'
                    : 'danger'
                }
                position="left"
                message={formInputs.button.informationMessage.value}
                handleOnClose={() => this.setState({
                  formInputs: {
                    ...formInputs,
                    button: {
                      ...formInputs.button,
                      informationMessage: {
                        ...formInputs.button.informationMessage,
                        display: false,
                      },
                    },
                  },
                })
                }
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default Contact;
