import CLASS_ICON from "./icons"

const LINKS = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/Sbibou.jr",
    classIcon: CLASS_ICON.FACEBOOK,
  },
  {
    name: "Linkedin",
    href: "https://www.linkedin.com/in/billy-ros-55608a142/",
    classIcon: CLASS_ICON.LINKEDIN,
  },
  {
    name: "Github",
    href: "https://github.com/SbibouJr",
    classIcon: CLASS_ICON.GITHUB,
  },
  {
    name: "Gitlab",
    href: "https://gitlab.com/SbibouJr",
    classIcon: CLASS_ICON.GITLAB,
  },
]

export default LINKS
