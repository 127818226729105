import React from "react"
import PropTypes from "prop-types"

import Icon from "../Icon"
import CLASS_ICON from "../../config/icons"

import "./style.less"
import TriangleSVG from "../TriangleSVG"

const INFORMATION_TYPE = {
  SUCCESS: "success",
  INFORMATION: "information",
  WARNING: "warning",
  DANGER: "danger",
}

const POSITION = {
  LEFT: "left",
  RIGHT: "right",
}

const InformationMessage = ({ message, position, type, handleOnClose }) => (
  <div
    className={`information-message ${
      Object.values(INFORMATION_TYPE).includes(type)
        ? type
        : INFORMATION_TYPE.DANGER
    }`}
  >
    <div
      className={`message-container ${
        Object.values(POSITION).includes(position) ? position : POSITION.LEFT
      }`}
    >
      <span className="message">{message}</span>
      <TriangleSVG direction="top" className="triangle-icon" />
      <span className="close-icon">
        <Icon classIcon={CLASS_ICON.TIMES} onClick={() => handleOnClose()} />
      </span>
    </div>
  </div>
)

InformationMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  position: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
}

InformationMessage.defaultProps = {
  message: "An Information Message",
  type: INFORMATION_TYPE.SUCCESS,
  position: POSITION.LEFT,
}

export default InformationMessage
