/**
 * Screen Size Constant
 */
const SCREEN_SIZE = {
  XS: 575,
  SM: 767,
  MD: 991,
  LG: 1199,
}

/**
 * Check if has window (browser) object (undefined on nodeJs)
 *
 * @return {bool} - true if has window (browser)
 */
function hasWindowObject() {
  if (typeof window !== "undefined") {
    return true
  }
  return false
}

/**
 * Get Window Scroll
 *
* @return {Object} - Window scroll { x, y }

*/
function getWindowScroll() {
  const scroll = {
    x: 0,
    y: 0,
  }

  if (hasWindowObject()) {
    scroll.x = window.scrollX
    scroll.y = window.scrollY
  }
  return scroll
}

/**
 * Scroll on the top of the page
 */
function scrollTop() {
  if (hasWindowObject()) {
    window.scrollTo(0, 0)
  }
}

/**
 * Set Scroll of the page (disable/enable)
 *
 * @param {bool} scroll - if false, disable the scroll page
 */
function setWindowScroll(scroll = true) {
  if (hasWindowObject()) {
    if (!scroll) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }
}

/**
 * Smooth Scroll on the top of the element
 *
 * @param {bool} element - to scroll
 */
function scrollIntoView(selector) {
  const element = document.querySelector(selector)
  if (element !== null) {
    element.scrollIntoView({ behavior: "smooth" })
  } else {
    console.error(`Element ${selector} not found.`) // eslint-disable-line
  }
}

/**
 * Check if user has a tactil screen
 *
 * @return {bool} - true if user has a tactil screen
 */
function isTouchScreen() {
  try {
    document.createEvent("TouchEvent")
    return true
  } catch (e) {
    return false
  }
}

/**
 * Is screen is XS < 576px
 *
 * @return {bool} - true if screen is < 576px
 */
function isXs() {
  if (hasWindowObject()) {
    return window.innerWidth <= SCREEN_SIZE.XS
  }
  return false
}

/**
 * Is screen is SM >= 576px and < 768px
 *
 * @return {bool} - true if screen is >= 576px and < 768px
 */
function isSm() {
  if (hasWindowObject()) {
    return (
      window.innerWidth > SCREEN_SIZE.XS && window.innerWidth <= SCREEN_SIZE.SM
    )
  }
  return false
}

/**
 * Is screen is MD >= 768px and < 992px
 *
 * @return {bool} - true if screen is >= 768px and < 992px
 */
function isMd() {
  if (hasWindowObject()) {
    return (
      window.innerWidth > SCREEN_SIZE.SM && window.innerWidth <= SCREEN_SIZE.MD
    )
  }
  return false
}

/**
 * Is screen is LG >= 992px and < 1200px
 *
 * @return {bool} - true if screen is >= 992px and < 1200px
 */
function isLg() {
  if (hasWindowObject()) {
    return (
      window.innerWidth > SCREEN_SIZE.MD && window.innerWidth <= SCREEN_SIZE.LG
    )
  }
  return false
}

/**
 * Is screen is XL >= 1200px
 *
 * @return {bool} - true if screen is >= 1200px
 */
function isXl() {
  if (hasWindowObject()) {
    return window.innerWidth > SCREEN_SIZE.LG
  }
  return false
}

export {
  hasWindowObject,
  getWindowScroll,
  scrollTop,
  setWindowScroll,
  scrollIntoView,
  isTouchScreen,
  isXs,
  isSm,
  isMd,
  isLg,
  isXl,
}
