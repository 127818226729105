import massonSculptureImg from "../assets/images/projects/massonSculpture.png"
import wickesImg from "../assets/images/projects/wickes.png"
import informatiqueLegaleImg from "../assets/images/projects/informatiqueLegale.png"

const PROJECTS = [
  {
    name: "Masson sculpture",
    link: "massonsculpture.fr",
    category: "Site Web, Application Mobile",
    description:
      "Développement d'un site web vitrine accompagné d'une zone administrateur en version web ainsi qu'en version application mobile.",
    image: massonSculptureImg,
  },
  {
    name: "Wickes",
    category: "Site Web",
    description:
      "Développement d'une application web de gestion de commandes/livraisons pour le groupe Wickes Uk.",
    image: wickesImg,
  },
  {
    name: "Informatique Légale",
    link: "informatique-legale.com",
    category: "Site Web",
    description: "Correction de divers bugs sur la partie responsive du site.",
    image: informatiqueLegaleImg,
  },
]

export default PROJECTS
