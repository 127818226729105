import React, { Fragment } from "react"
import PropTypes from "prop-types"

import "./style.less"

const DIRECTION = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
}

const POLYGON_POINTS = {
  [DIRECTION.LEFT]: "0.5,0 0,0.5 0.5,1",
  [DIRECTION.RIGHT]: "0,0 0.5,0.5 0,1",
  [DIRECTION.TOP]: "0,0.5 0.5,0 1,0.5",
  [DIRECTION.BOTTOM]: "0,0 0.5,0.5 1,0",
}

const getPolygon = value => {
  const directionValue = Object.values(DIRECTION).includes(value)
    ? value
    : DIRECTION.TOP
  return <polygon points={POLYGON_POINTS[directionValue]}></polygon>
}

const TriangleSVG = ({ direction, className, ...rest }) => (
  <Fragment>
    <span
      {...rest}
      className={`svg-icon ${className}`}
      style={{
        WebkitClipPath: `url(#triangle-${direction}-svg)`,
        clipPath: `url(#triangle-${direction}-svg)`,
      }}
    ></span>
    <svg height="0" width="0">
      <defs>
        <clipPath
          id={`triangle-${direction}-svg`}
          clipPathUnits="objectBoundingBox"
        >
          {getPolygon(direction)}
        </clipPath>
      </defs>
    </svg>
  </Fragment>
)

TriangleSVG.propTypes = {
  direction: PropTypes.string,
  className: PropTypes.string,
}

TriangleSVG.defaultProps = {
  direction: DIRECTION.TOP,
  className: "",
}

export default TriangleSVG

export { DIRECTION }
