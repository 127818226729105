const CLASS_ICON = {
  TIMES: "fas fa-times",
  FACEBOOK: "fab fa-facebook",
  LINKEDIN: "fab fa-linkedin",
  GITHUB: "fab fa-github",
  GITLAB: "fab fa-gitlab",
  CHALKBOARD_TEACHER: "fas fa-chalkboard-teacher",
  MOBILE_ALT: "fas fa-mobile-alt",
  COGS: "fas fa-cogs",
}

export default CLASS_ICON
