import React from "react"

import LinksList from "../LinksList"
import cvPdf from "../../assets/pdf/cv_billyros.pdf"

import lessStyle from "./style.module.less"

const About = () => (
  <div id="anchor-about" className={lessStyle.about}>
    <h2 className="intersection-observed">A propos</h2>
    <p className="intersection-observed">
      Je m'appel <strong>billy ros</strong>.<br />
      Développeur web de {new Date().getFullYear() - 2014} années d'expérience,
      <br />
      j'ai collaboré et développé des sites web pour divers marques de secteurs
      différents.
    </p>
    <p>
      <button
        className="button intersection-observed"
        type="button"
        onClick={() => window.open(cvPdf)}
      >
        Voir mon cv
      </button>
    </p>
    <p>
      <button
        className="button intersection-observed"
        type="button"
        disabled
        onClick={() => {}}
      >
        Voir mon portfolio
      </button>
    </p>
    <p className="intersection-observed text-left">
      Mon environnement de travail tourne autour du <strong>javascript</strong>{" "}
      et est composé entre autres de:
    </p>
    <ul className="text-left list">
      <li className="intersection-observed">React</li>
      <li className="intersection-observed">React Native</li>
      <li className="intersection-observed">Node</li>
      <li className="intersection-observed">Gatsby</li>
      <li className="intersection-observed">Webpack</li>
      <li className="intersection-observed">Git/Github/Gitlab</li>
    </ul>
    <h3 className="intersection-observed text-center">
      Vous pouvez me retrouver sur
    </h3>
    <LinksList />
  </div>
)

export default About
