import CLASS_ICON from "./icons"

const SERVICES = [
  {
    name: "Site Web",
    description: "Développement d'un site/application web pour votre activité.",
    title: "Besoin d'un site web ?",
    logo: CLASS_ICON.CHALKBOARD_TEACHER,
  },
  {
    name: "Application Mobile",
    description: "Développement d'application mobile Android et IOS.",
    title: "Besoin d'une application mobile ?",
    logo: CLASS_ICON.MOBILE_ALT,
  },
  {
    name: "Autres Prestations",
    description:
      "Nouvelles fonctionnalités, correction de bugs, refonte, autres...",
    title: "Un besoin spécifique ?",
    logo: CLASS_ICON.COGS,
  },
]

export default SERVICES
