import React from "react"
import lessStyle from "./style.module.less"

import logoSrc from "../../assets/images/logos/rb.jpg"
import { scrollIntoView } from "../../utils/screens"

const Home = () => (
  <div className={lessStyle.home}>
    <h1 className="intersection-observed">Développement Web</h1>
    <div className={lessStyle.companyLogo}>
      <img
        className="intersection-observed"
        src={logoSrc}
        alt="Logo de Billy Ros"
        title="Billy Ros Web Development"
      />
    </div>
    <p className={`${lessStyle.introduction} intersection-observed`}>
      Développeur passionné spécialisé dans le <strong>web</strong>, je propose
      mes services pour la création de
      <strong> sites</strong> internet, d'<strong>applications </strong>
      mobile ou pour des développements <strong>spécifiques</strong>.<br />
      J'aide les <strong>entreprises</strong> et les{" "}
      <strong>particuliers </strong>à développer leurs systèmes d'information en
      participant activement au développement de leurs <strong>projets</strong>.
    </p>
    <p>
      <button
        type="button"
        className="button intersection-observed"
        onClick={() => scrollIntoView("#anchor-about")}
      >
        En savoir plus
      </button>
    </p>
  </div>
)

export default Home
