import React from "react"
import Icon from "../Icon"

import "./style.less"

import LINKS from "../../config/links"

const LinksList = () => (
  <ul className="intersection-observed links-list">
    {LINKS.map(link => (
      <li key={link.href}>
        <a
          href={link.href}
          title={link.name}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon classIcon={link.classIcon} />
        </a>
      </li>
    ))}
  </ul>
)

export default LinksList
